import React from "react";
import { Route } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import Loading from "../Views/Loading";
import Layout from "../Views/Layout";
import { UsersProvider } from "../Providers/UsersProvider";
import { OrganizationProvider } from "../Providers/OrganizationProvider";

export function PrivateRoute(props: any) {
  const { component, ...rest } = props;
  const auth = useAuth();

  const renderFn = (Component: any) => (props: any) => {
    if (!!Component && auth.isAuthenticated()) {
      return (
        <UsersProvider>
          <OrganizationProvider>
            <Layout><Component {...props} /></Layout>
          </OrganizationProvider>
        </UsersProvider>
      );
    } else {
      auth.loginRedirect();
    }
  };

  return <Route {...rest} render={renderFn(component)} />;
}