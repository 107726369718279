import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import { PagedTickets, Ticket, TicketStatus } from '../../types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pager from '../Shared/Pager';
import LetterFilter from '../Shared/LetterFilter';

interface Props {
  companyId?:string;
  contactId?:string;
}

const ticketStatusKey = {
  [TicketStatus.Open]: "Open",
  [TicketStatus.Closed]: "Closed",
  [TicketStatus.Reopened]: "Re-Opened",
  [TicketStatus.Waiting]: "Waiting"
}

export default function TicketTable(props:Props) {
  const [loading, setLoading] = React.useState(true);
  const [tickets, setTickets] = React.useState<Array<Ticket>>([]);
  const [page, setPage] = React.useState(0);
  const [totatRecordCount, setTotalRecordCount] = React.useState(0);
  const [filter, setFilter] = React.useState("");
  const api = useApi();

  const loadTickets = React.useCallback(async() => {
    setLoading(true);
    const response = await api.get('api/tickets/page', {page, filter, companyId: props.companyId, contactId: props.contactId});
    const paged = (await response.json()) as PagedTickets;
    setTickets(paged.tickets);
    setTotalRecordCount(paged.totalRecordCount);
    setLoading(false);
  }, [api, filter, page, props.companyId, props.contactId]);

  React.useEffect(() => {
    loadTickets();
  }, [page, filter, loadTickets]);


  const renderLoading = React.useCallback(() => {
    if (!loading) {
      return null;
    }
    return <tr style={{ background: "transparent" }}>
      <td colSpan={100}>
        <Loading />
      </td>
    </tr>
  }, [loading]);

  const renderTicket = React.useCallback((ticket: Ticket) => {
    const href = `/tickets/${ticket.id}`;
    return (
      <tr key={ticket.id}>
        <td><Link to={href} >{ticket.title}</Link></td>
        <td>{moment.utc(ticket.createdOn).local().calendar()}</td>
        <td>{moment.utc(ticket.lastUpdated).local().calendar()}</td>
        <td>{ticketStatusKey[ticket.status]}</td>
      </tr>
    )
  },[]);

  const renderEmpty = React.useCallback(() => {
    if (tickets.length || loading) {
      return null;
    }

    return (
      <tr>
        <td className="text-center" colSpan={100}>
          <h6>No results</h6>
        </td>
      </tr>
    )
  }, [tickets,loading]);

  const updateFilter = React.useCallback((value:string) => {
    setPage(0);
    setFilter(value);
  }, [])


  return (
    <>
    {props.companyId &&
        <div className="row">
          <div className="col-md-12" style={{marginBottom:"10px"}}>
            <div className="float-right">
              <Link to={`/tickets/add?companyId=${props.companyId}`} className="btn btn-primary float-right">Add Ticket</Link>
              <div className="clearfix" />
            </div>
          </div>
        </div>}
        {props.contactId &&
        <div className="row">
          <div className="col-md-12" style={{marginBottom:"10px"}}>
            <div className="float-right">
              <Link to={`/tickets/add?contactId=${props.contactId}`} className="btn btn-primary float-right">Add Ticket</Link>
              <div className="clearfix" />
            </div>
          </div>
        </div>}
      <div className="row">
        <div className="col-md-8">
          <LetterFilter filter={filter} setFilter={updateFilter} />
        </div>
        <div className="col-md-4">
          <div className="float-right">
            <Pager page={page} pageSize={20} totalRecordCount={totatRecordCount} setPage={setPage} />
          </div>
        </div>
      </div>

      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created On</th>
            <th>Last Updated</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {renderLoading()}
          {tickets.map(renderTicket)}
          {renderEmpty()}
        </tbody>
      </table>
    </>
  );
}