import React from 'react';

interface Props {
    page: number;
    setPage(page: number): void;
    totalRecordCount: number;
    pageSize: number;
}

export default function Pager(props: Props) {
    const numberOfPages = Math.ceil(props.totalRecordCount / props.pageSize);

    var renderPages = React.useCallback(() => {
        let startPage = props.page - 2;
        let endPage = props.page + 2;
        if (startPage < 0) {
            startPage = 0;
        }

        if (startPage > 2 && endPage > props.page + 2) {
            endPage = props.page + 2;
        }

        if (startPage <= 2 && endPage > 4) {
            endPage = 4;
            startPage = 0;
        }

        if (endPage > 4) {
            startPage = endPage - 4;
        }

        const maxPage = props.totalRecordCount / props.pageSize;
        if (endPage > maxPage) {
            endPage = maxPage;
        }

        let pages = [];
        for (var i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        return pages.map((p) => {
            let className = "page-item";
            if (p === props.page) {
                className += " active";
            }
            return <li key={p} className={className}><button className="page-link" onClick={() => props.setPage(p)} >{p + 1}</button></li>
        })


    }, [props])

    if (numberOfPages < 1) {
        return null;
    }

    return (
        <nav aria-label="Navigation">
            <ul className="pagination">
                <li className="page-item">
                    <button className="page-link" onClick={() => props.setPage(props.page > 0 ? props.page - 1 : 0)} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </button>
                </li>
                {renderPages()}
                <li className="page-item">
                    <button className="page-link" onClick={() => props.setPage(props.page === (numberOfPages - 1) ? props.page : props.page + 1)} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </button>
                </li>
            </ul>
        </nav>
    )
}