import React from 'react';

interface Props {
    setValue(input:string): void;
    label: string;
    value: string;
}

export default function TextControl(props: Props){
    return (
        <div className="form-group">
            <label className="form-label">{props.label}</label>
            <input type="text" className="form-control" value={props.value} onChange={e => props.setValue(e.currentTarget.value)} />
          </div>
    )
}