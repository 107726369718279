import React from "react";
import { Organization } from "../types";

export const OrganizationContext = React.createContext<Organization>({
    velaroSiteId: undefined,
    expirationDate: new Date(),
    name:"",
    velaroUrl:"",
    customFieldDefinition: {
      address: [],
      ticket: [],
      contact: [],
      company:[]
    }
  });