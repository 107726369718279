import React from 'react';

interface Props {
    showing: string;
    label:string;
    setShowing:any;
}

export default function Tab(props: Props) {
    return (
        <li className="nav-item">
          <a href="javascript(void)" className={"nav-link" + (props.showing === props.label ? " active" : "")} onClick={e => {
                e.preventDefault();
                props.setShowing(props.label);
            }}>{props.label}</a>
        </li>
    )
}