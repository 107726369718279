import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import TextControl from '../Shared/TextControl';
import SelectControl from '../Shared/SelectControl';
import {CustomFields, SelectOption, Ticket, TicketStatus } from '../../types';
import { useHistory } from 'react-router-dom';
import Notes from '../Shared/Notes';
import Tab from '../Shared/Tab';
import TabContent from '../Shared/TabContent';
import TabHeader from '../Shared/TabHeader';
import TextAreaControl from '../Shared/TextAreaControl';
import TicketStatusOptions from '../../Assets/TicketStatus';
import useQuery from '../../Hooks/useQuery';
import FileTable from '../Shared/FileTable';
import CustomFieldsEditor from '../Shared/CustomFieldsEditor';

interface Props {
  match: any
}

export default function EditTicket(props: Props) {
  const query = useQuery();
  const history = useHistory();
  const api = useApi();
  const [loading, setLoading] = React.useState(true);
  const [id, setId] = React.useState(props.match?.params?.id);
  const [header, setHeader] = React.useState("Add Ticket");
  const [title, setTitle] = React.useState('');
  const [status, setStatus] = React.useState<TicketStatus>(TicketStatus.Open);
  const [description, setDescription] = React.useState("");
  const [companyId, setCompanyId] = React.useState(query.get("companyId") || "");
  const [contactId, setContactId] = React.useState(query.get("contactId") || "");
  const [companyOptions, setCompanyOptions] = React.useState<Array<SelectOption>>([]);
  const [contactOptions, setContactOptions] = React.useState<Array<SelectOption>>([]);
  const [showing, setShowing] = React.useState< "Notes" | "Files">("Notes");
  const [customFields, setCustomFields] = React.useState<CustomFields>(
    {address:{},
    company: {},
    contact: {},
    ticket:{}
  });


  const loadTicket = React.useCallback(async() => {
    if(!id){
      return;
    }
    const response = await api.get(`api/tickets/retrieve/${id}`);
    const ticket = (await response.json()) as Ticket;
    setId(ticket.id);
    setCompanyId(ticket.companyId || '');
    setContactId(ticket.contactId || '');
    setTitle(ticket.title);
    setDescription(ticket.description);
    setHeader("Edit Ticket");
    setStatus(ticket.status);
    setCustomFields(ticket.customFields);
  }, [api, id]);

  const loadContactOptions = React.useCallback(async() => {
    const response = await api.get('api/contacts/options', {companyId});
    const options = (await response.json());
    setContactOptions(options);
  },[api, companyId]);

  const renderTabs = React.useCallback(() => {
    if(!id){
      return null;
    }

    return (
      <>
      <TabHeader>
        <Tab label="Notes" showing={"showing"} setShowing={setShowing} />
        {id && <Tab label="Files" showing={showing} setShowing={setShowing} />}
      </TabHeader>
      <TabContent>
        {showing === "Notes" && <Notes ticketId={id} />}
        {showing === "Files" && <FileTable ticketId={id} />}
      </TabContent>
      </>
    )
  },[id, showing]);

  React.useEffect(() => {
    loadContactOptions();
  }, [companyId, loadContactOptions])

  const loadCompanyOptions = React.useCallback(async() => {
    const response = await api.get('api/companies/options');
    const options = (await response.json());
    setCompanyOptions(options);
  },[api]);

  
  const load = React.useCallback(async() => {
    await Promise.all([loadTicket(), loadCompanyOptions(), loadContactOptions()])
    setLoading(false);
  },[loadTicket, loadCompanyOptions, loadContactOptions]);

  React.useEffect(() => {
    load();
  }, [id, load]);

  if (loading) {
    return <Loading />
  }

  async function saveTicket() {
    const req = {
      id,
      companyId: companyId || undefined,
      contactId: contactId || undefined,
      title,
      description,
      status,
      customFields
    };

    const response = req.id ? await api.put('api/tickets', req) : await api.post('api/tickets', req);

    if (response.ok) {
      const ticket = (await response.json()) as Ticket;
      history.push(`/tickets/${ticket.id}`);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h3 className="mb-0 color-base">{header}</h3>
          <hr className="mb-4 color-accentdark" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h4>Details</h4>
          <TextControl label="Title" value={title} setValue={setTitle} />
          <SelectControl label="Status" value={status} setValue={setStatus} options={TicketStatusOptions} />
          <TextAreaControl label="Description" value={description} setValue={setDescription} />
          <SelectControl label="Company" allowEmpty={true} value={companyId} setValue={setCompanyId} options={companyOptions} />
          <SelectControl label="Contact" allowEmpty={true} value={contactId} setValue={setContactId} options={contactOptions} />
          <CustomFieldsEditor customInformation={customFields} setValue={setCustomFields} type="ticket" />
        </div>
        <div className="col-md-6">
          
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button className="btn btn-success float-right" onClick={saveTicket}>Save</button>
        </div>
      </div>

      {renderTabs()}
    </>
  );
}