import React from 'react';
import { Link } from 'react-router-dom';
import { SyncIcon, ThreeBarsIcon } from '@primer/octicons-react'
import _ from 'underscore';
import useApi from '../Hooks/useApi';
import { SearchResult } from '../types';
import logoUrl from "../Assets/logo.png";



export default function Navbar() {
    const [searchResults, setSearchResults] = React.useState<Array<SearchResult>>([]);
    const [searching, setSearching] = React.useState(false);
    const [showingSearchResults, setShowingSearchResults] = React.useState(false);
    const [term, setTerm] = React.useState('');

    const api = useApi();
    const search = _.debounce(async (term: string) => {
        setSearching(true);
        const response = await api.get('api/record/search', { term });
        const results = await response.json();
        setSearchResults(results);
        setShowingSearchResults(true);
        setSearching(false);
    }, 1000);

    React.useEffect(() => {
        document.body.addEventListener('click',  closeSearchResults);

        return function cleanup() {
            window.removeEventListener('click', closeSearchResults );
        } 
    },[]);

    const closeSearchResults = () => {
        setShowingSearchResults(false);
        setSearchResults([]);
        setTerm('');
    }

    const debouncedSearch = React.useCallback(e => {
        const term = e.currentTarget.value;
        setTerm(term);
        if (!term) {
            return;
        }
        search(term);
    }, [search]);

    const apiMap = {
        "Contact": "contacts",
        "Ticket": "tickets",
        "Company": "companies"
    }

    const renderSearchResults = React.useCallback(() => {
        if (!showingSearchResults) {
            return null;
        }

        if (!searchResults.length) {
            return (
                <ul className="nav-search nav-search-results">
                    <li className="no-results">No results</li>
                </ul>
            )
        }

        return (
            <ul className="nav-search nav-search-results">
                {searchResults.map(sr => {
                    return (
                        <li key={sr.id}><Link to={`/${apiMap[sr.recordType]}/${sr.id}`}>{sr.name} ({sr.recordType})</Link></li>
                    )
                })}
            </ul>
        )
    }, [searchResults, showingSearchResults, apiMap]);

    return (
        <nav className="navbar navbar-expand-md">

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                <ThreeBarsIcon size="medium" />
            </button>

            <Link className="navbar-brand" to="/"><img src={logoUrl} alt="Logo" /><span>contact center</span></Link>
            <div className="nav-search">
                <div className="col-lg-4 col-md-4 d-none d-md-block" >
                    <input type="text" value={term} onChange={debouncedSearch} className="form-control" placeholder="Find..." />
                    <div className="nav-search-icon">
                        {searching && <SyncIcon className="loading-spinner" />}
                    </div>
                    {renderSearchResults()}
                </div>
            </div>

        </nav>
    );
}