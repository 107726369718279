import useOrganization from '../../Hooks/useOrganization';
import { CustomFields, FieldDefinition } from '@/src/types';
import React from 'react';
import TextControl from './TextControl';
import TextAreaControl from './TextAreaControl';
import SelectControl from './SelectControl';
import CheckboxControl from './CheckboxControl';

interface Props {
    customInformation:CustomFields;
    setValue(model:any):void;
    type:"contact" | "company" | "address" | "ticket";
}

export default React.memo((props: Props) => {
    const org = useOrganization();
    const definitions = org.customFieldDefinition[props.type];
    if(definitions.length === 0){
        return null;
    }

    const setFieldValue = function(partial:any){
        const update = {[props.type]:{...props.customInformation[props.type],...partial}};
        const customInformation = {...props.customInformation, ...update};
        props.setValue(customInformation)
    }

    const renderDefinition = React.useCallback((definition:FieldDefinition) =>{
        const record = props.customInformation && props.customInformation[props.type] ? props.customInformation[props.type] as any : {};
        const fieldValue = record[definition.label] || "";
        
        if(definition.type === "text"){
            return (
                    <TextControl value={fieldValue} label={definition.label} setValue={val => setFieldValue({[definition.label]: val})} />
            )
        }
        
        if(definition.type === "textarea"){
            return (
                    <TextAreaControl value={fieldValue} label={definition.label} setValue={val => setFieldValue({[definition.label]: val})} />
            )
        }
        
        if(definition.type === "select"){
            return (
                    <SelectControl allowEmpty={true} options={definition.options} value={fieldValue} label={definition.label} setValue={val => setFieldValue({[definition.label]: val})} />
            )
        }
        
        if(definition.type === "checkbox"){
            return (
                    <CheckboxControl value={fieldValue} label={definition.label} setValue={val => setFieldValue({[definition.label]: val})} />
            )
        }
    },[props]);

    return (
        <>
        {definitions.map(renderDefinition)}
        </>
    )
})