import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import { Contact } from '../../types';
import TextDisplay from '../Shared/TextDisplay';
import Notes from '../Shared/Notes';
import Display from '../Shared/Display';
import DisplayLink from '../Shared/DisplayLink';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TabHeader from '../Shared/TabHeader';
import AddressDisplay from '../Shared/AddressDisplay';
import Tab from '../Shared/Tab';
import TabContent from '../Shared/TabContent';
import TicketTable from '../Ticket/TicketTable';
import Timeline from '../Shared/Timeline';
import { OrganizationContext } from '../../Contexts/OrganizationContext';
import TranscriptTable from '../Shared/TranscriptTable';
import FileTable from '../Shared/FileTable';
import CustomFieldsDisplay from '../Shared/CustomFieldsDisplay';

interface Props {
  match: any;
}

export default function ViewContact(props: Props) {
  const api = useApi();
  const [contact, setContact] = React.useState<Contact>({
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      state: "",
      zipCode: ""
    },
    emailAddress: "",
    name: "",
    phoneNumber: "",
    customFields: {
      address: {},
      company: {},
      contact: {},
      ticket: {}
    }
  });
  const [showing, setShowing] = React.useState<"Notes" | "Tickets" | "Address" | "Transcripts" | "Files">("Notes");
  const [loading, setLoading] = React.useState<boolean>(true);

  const loadContact = React.useCallback(async () => {
    const response = await api.get(`api/contacts/retrieve/${props.match.params.id}`);
    setContact(await response.json());
    setLoading(false);
  }, [api, props.match.params.id]);

  React.useEffect(() => {
    loadContact();
  }, [loadContact]);

  if (loading) {
    return <Loading />
  }

  const editLink = `/contacts/edit/${contact.id}`;

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <h3 className="mb-0">{contact.name}</h3>
        </div>
        <div className="col-md-4">
          <Link to={editLink} className="btn btn-primary float-right">Edit Contact</Link>
        </div>
        <div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <hr className="mb-4 color-accentdark" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h4>Details</h4>
          <DisplayLink label="Company" value={contact.companyName} href={"/companies/" + contact.companyId} />
          <Display label="Created" value={moment.utc(contact.createdOn).local().calendar()} />
          <Display label="Last Updated" value={moment.utc(contact.lastUpdated).local().calendar()} />
          <TextDisplay label="Phone Number" value={contact.phoneNumber} />
          <TextDisplay label="Email Address" value={contact.emailAddress} />
          <CustomFieldsDisplay type="contact" customInformation={contact.customFields} />
        </div>
        <div className="col-md-6">
          <Timeline contactId={contact.id} />
        </div>
      </div>

      <TabHeader>
        <Tab label="Notes" showing={showing} setShowing={setShowing} />
        <Tab label="Address" showing={showing} setShowing={setShowing} />
        <Tab label="Tickets" showing={showing} setShowing={setShowing} />
          <OrganizationContext.Consumer>
            {org => 
              org?.velaroSiteId && <Tab label="Transcripts" showing={showing} setShowing={setShowing} ></Tab>
            }
          </OrganizationContext.Consumer>
          
        <Tab label="Files" showing={showing} setShowing={setShowing} />
      </TabHeader>
      <TabContent>
        {showing === "Notes" && <Notes contactId={contact.id} />}
        {showing === "Address" && <AddressDisplay customInformation={contact.customFields} address={contact.address} />}
        {showing === "Tickets" && <TicketTable contactId={contact.id} />}
        {showing === "Transcripts" && <TranscriptTable contactId={contact.id} />}
        {showing === "Files" && <FileTable contactId={contact.id} />}
      </TabContent>
    </>
  );
}