import React from "react";
import useApi from "../../Hooks/useApi";
import { Organization, TranscriptReference } from "../../types";
import Loading from "../Loading";
import * as $ from "jquery";
import moment from "moment";
import { OrganizationContext } from "../../Contexts/OrganizationContext";
import useAuth from "../../Hooks/useAuth";

interface Props {
    companyId?:string;
    contactId?:string;
    ticketId?:string;
}

export default (props:Props) => {
    const api = useApi();
    const auth = useAuth();
    const [loading, setLoading] = React.useState(true);
    const [transcripts, setTranscripts] = React.useState<Array<TranscriptReference>>([]);

    const load = React.useCallback(async() =>{
        const response = await api.get(`api/transcript?${$.param(props)}`);
        setTranscripts(await response.json());
        setLoading(false);
    },[api])

    React.useEffect(() =>{
        load();
    }, [load]);

    const renderTranscript = React.useCallback((transcript: TranscriptReference, organization: Organization | undefined) => {
        const transcriptUrl = `/transcript/show/${transcript.id}`;
        const passThroughUrl = `${organization?.velaroUrl}/account/passthrough`;
        const href = `${passThroughUrl}?token=${encodeURIComponent(auth.legacyToken.token)}&returnUrl=${encodeURIComponent(transcriptUrl)}`;
        return (
          <tr key={transcript.id}>
            <td><a href={href} target="_blank" >{moment.utc(transcript.createdOn).local().calendar()}</a></td>
          </tr>
        )
      },[]);

    const renderEmpty = React.useCallback(() => {
        if (transcripts.length || loading) {
          return null;
        }
    
        return (
          <tr>
            <td className="text-center" colSpan={100}>
              <h6>No results</h6>
            </td>
          </tr>
        )
      }, [transcripts,loading]);


    if(loading){
        return <Loading />
    }

    return (
        <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
            <OrganizationContext.Consumer>
                {org => transcripts.map(x => renderTranscript(x, org))}
            </OrganizationContext.Consumer>
          {renderEmpty()}
        </tbody>
      </table>
    )
}