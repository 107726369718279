import React from "react";
import useAuth from "./useAuth";
import $ from 'jquery';

class Api {
  accessToken: string;

  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  get(endpoint: string, data?:any) {
    return fetch(`${import.meta.env.VITE_API_URL}/${endpoint}${data ? "?" + $.param(data) : ''}`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  post(endpoint: string, data?: any) {
    return fetch(`${import.meta.env.VITE_API_URL}/${endpoint}`, {
      method: "post",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  uploadFile(endpoint: string, data: FormData) {
    return fetch(`${import.meta.env.VITE_API_URL}/${endpoint}`, {
      method: "post",
      body: data,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  put(endpoint: string, data?: any) {
    return fetch(`${import.meta.env.VITE_API_URL}/${endpoint}`, {
      method: "put",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  delete(endpoint: string, data?: any) {
    return fetch(`${import.meta.env.VITE_API_URL}/${endpoint}`, {
      method: "delete",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }
}

export default function useApi() {
  const auth = useAuth();
  return React.useMemo(() => new Api(auth.token.token), [auth.token.token]);
}