import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import { Company, PagedCompanies } from '../../types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pager from '../Shared/Pager';
import LetterFilter from '../Shared/LetterFilter';

export default function Companies() {
  const [loading, setLoading] = React.useState(true);
  const [companies, setCompanies] = React.useState<Array<Company>>([]);
  const [page, setPage] = React.useState(0);
  const [totatRecordCount, setTotalRecordCount] = React.useState(0);
  const [filter, setFilter] = React.useState("");
  const api = useApi();

  const loadCompanies = React.useCallback(async() => {
    setLoading(true);
    const response = await api.get('api/companies/page', {page, filter});
    const pagedCompanies = (await response.json()) as PagedCompanies;
    setCompanies(pagedCompanies.companies);
    setTotalRecordCount(pagedCompanies.totalRecordCount);
    setLoading(false);
  },[page, filter, api]);

  React.useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);


  const renderLoading = React.useCallback(() => {
    if (!loading) {
      return null;
    }
    return <tr style={{ background: "transparent" }}>
      <td colSpan={100}>
        <Loading />
      </td>
    </tr>
  }, [loading]);


  const renderCompany = React.useCallback((company: Company) => {
    const href = `/companies/${company.id}`;
    return (
      <tr key={company.id}>
        <td><Link to={href} >{company.name}</Link></td>
        <td>{moment.utc(company.createdOn).local().calendar()}</td>
        <td>{moment.utc(company.lastUpdated).local().calendar()}</td>
        <td>{company.phoneNumber}</td>
        <td>{company.emailAddress}</td>
      </tr>
    )
  },[]);

  const renderEmpty = React.useCallback(() => {
    if (companies.length || loading) {
      return null;
    }

    return (
      <tr>
        <td className="text-center" colSpan={100}>
          <h6>No results</h6>
        </td>
      </tr>
    )
  }, [companies,loading]);

  const updateFilter = React.useCallback((value:string) => {
    setPage(0);
    setFilter(value);
  }, [])


  return (
    <>

      <div className="row">
        <div className="col-md-6">
          <h3 className="pb-3">Companies</h3>
        </div>
        <div className="col-md-6">
          <Link to="/companies/add" className="btn btn-primary float-right">Add Company</Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <LetterFilter filter={filter} setFilter={updateFilter} />
        </div>
        <div className="col-md-4">
          <div className="float-right">
            <Pager page={page} pageSize={20} totalRecordCount={totatRecordCount} setPage={setPage} />
          </div>
        </div>
      </div>

      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created On</th>
            <th>Last Updated</th>
            <th>Phone Number</th>
            <th>Email Address</th>
          </tr>
        </thead>
        <tbody>
          {renderLoading()}
          {companies.map(renderCompany)}
          {renderEmpty()}
        </tbody>
      </table>
    </>
  );
}