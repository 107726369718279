import React from 'react';
import TextControl from './TextControl';
import SelectControl from './SelectControl';
import { Address, CustomFields } from '../../types';
import StateCodes from '../../Assets/StateCodes';
import CustomFieldsEditor from './CustomFieldsEditor';

interface Props {
    address: Address;
    setAddressLine1(input:string):void;
    setAddressLine2(input:string):void;
    setCity(input:string):void;
    setState(input:string):void;
    setZipCode(input:string):void;
    setCountry(input:string):void;
    setCustomFields(input:CustomFields):void;
    customFields:CustomFields;
}

export default React.memo((props: Props) => {
    return (
        <>
            <TextControl label="Address Line 1" value={props.address.addressLine1} setValue={props.setAddressLine1} />
            <TextControl label="Address Line 2" value={props.address.addressLine2} setValue={props.setAddressLine2} />
            <TextControl label="City" value={props.address.city} setValue={props.setCity} />
            <SelectControl label="State" allowEmpty={true} value={props.address.state} setValue={props.setState} options={StateCodes} />
            <TextControl label="Zip Code" value={props.address.zipCode} setValue={props.setZipCode} />
            <TextControl label="Country" value={props.address.country} setValue={props.setCountry} />
            <CustomFieldsEditor customInformation={props.customFields} setValue={props.setCustomFields} type="address" />
        </>
    )
})