import React from 'react';

interface Props {
    
}

export default function TabHeader(props: React.PropsWithChildren<Props>) {
    return (
        <div className="row mt-3">
            <div className="col-md-12">
                <ul className="nav nav-pills">
                    {props.children}
                </ul>
            </div>
        </div>
    )
}