import React from 'react';
import { Link } from 'react-router-dom';
import { OrganizationIcon, HomeFillIcon, PeopleIcon, ChecklistIcon, SignOutIcon, GearIcon } from '@primer/octicons-react'
import authService from '../Services/AuthService';


export default function Navbar() {
    
    const [path, setPath] = React.useState(window.location.pathname);

    var onClickLink = React.useCallback((e) => {
        setPath(e.currentTarget.pathname);
    },[setPath]);

    var getClassName = React.useCallback((link:string) => {
        var className = "nav-link";
        if(link === path){
            className += " active";
        }

        return className;
    }, [path]);


    return (
        <nav id="sidebar" className="col-md-3 col-lg-2 d-md-block sidebar collapse pt-0">
            <div className="sidebar-sticky">
                <ul className="nav flex-column mb-2">
                    <li className="nav-item">
                        <Link to="/"  onClick={onClickLink} className={getClassName("/")}>
                            <HomeFillIcon size="small" verticalAlign="middle" />
                            &nbsp;Dashboard
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/companies"  onClick={onClickLink} className={getClassName("/companies")}>
                            <OrganizationIcon size="small" verticalAlign="middle" />
                            &nbsp;Companies

                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contacts"  onClick={onClickLink} className={getClassName("/contacts")}>
                            <PeopleIcon size="small" verticalAlign="middle" />
                            &nbsp;Contacts
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/tickets"  onClick={onClickLink} className={getClassName("/tickets")}>
                            <ChecklistIcon size="small" verticalAlign="middle" />
                            &nbsp;Tickets
                        </Link>
                    </li>
                </ul>
                <h5 className="ml-2 mb-0">Account</h5>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <Link onClick={() => authService.logout()} className={getClassName("/signout")}>
                            <SignOutIcon size="small" verticalAlign="middle" />
                            &nbsp;Sign out
                        </Link>
                    </li>
                </ul>
            </div>
        </nav >
    );
} 