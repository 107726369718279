import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import TextControl from '../Shared/TextControl';
import { Company, CustomFields } from '../../types';
import { useHistory } from 'react-router-dom';
import TabHeader from '../Shared/TabHeader';
import Tab from '../Shared/Tab';
import TabContent from '../Shared/TabContent';
import ContactTable from '../Contact/ContactTable';
import Notes from '../Shared/Notes';
import AddressEditor from '../Shared/AddressEditor';
import TicketTable from '../Ticket/TicketTable';
import FileTable from '../Shared/FileTable';
import CustomFieldsEditor from '../Shared/CustomFieldsEditor';

interface Props {
  match: any
}

export default function EditCompany(props: Props) {
  const history = useHistory();
  const api = useApi();
  const [name, setName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [id, setId] = React.useState(props.match.params.id);
  const [showing, setShowing] = React.useState<"Notes" | "Tickets" | "Contacts" | "Address" | "Files">(id ? "Notes" : "Address");
  const [loading, setLoading] = React.useState(id);
  const [title, setTitle] = React.useState("Add Company");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [state, setState] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [customFields, setCustomFields] = React.useState<CustomFields>(
    {address:{},
    company: {},
    contact: {},
    ticket:{}
  });

  const loadCompany = React.useCallback(async() => {
    if (!id) {
      return;
    }
    const response = await api.get(`api/companies/retrieve/${id}`);
    const company = (await response.json()) as Company;
    setId(company.id);
    setName(company.name);
    setPhoneNumber(company.phoneNumber);
    setEmailAddress(company.emailAddress);
    setTitle("Edit Company");
    setLoading(false);
    setAddressLine1(company.address.addressLine1);
    setAddressLine2(company.address.addressLine2);
    setCity(company.address.city)
    setState(company.address.state);
    setCountry(company.address.country);
    setZipCode(company.address.zipCode);
    setCustomFields(company.customFields)
  },[api, id]);

  React.useEffect(() => {
    loadCompany();
  }, [id, loadCompany]);

  if (loading) {
    return <Loading />
  }

  async function saveCompany() {
    const req = {
      emailAddress,
      name,
      phoneNumber,
      address: {
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
        country
      },
      customFields,
      id
    };

    const response = req.id ? await api.put('api/companies', req) : await api.post('api/companies', req);

    if (response.ok) {
      const company = (await response.json()) as Company;
      history.push(`/companies/${company.id}`);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h3 className="mb-0 color-base">{title}</h3>
          <hr className="mb-4 color-accentdark" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h4>Details</h4>
          <TextControl label="Company Name" value={name} setValue={setName} />
          <TextControl label="Phone Number" value={phoneNumber} setValue={setPhoneNumber} />
          <TextControl label="Email Address" value={emailAddress} setValue={setEmailAddress} />
          <CustomFieldsEditor customInformation={customFields} setValue={setCustomFields} type="company" />
        </div>
        <div className="col-md-6">

        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button className="btn btn-success float-right" onClick={saveCompany}>Save</button>
        </div>
      </div>

      <TabHeader>
        {id && <Tab label="Notes" showing={showing} setShowing={setShowing} />}
        <Tab label="Address" showing={showing} setShowing={setShowing} />
        {id && <Tab label="Tickets" showing={showing} setShowing={setShowing} />}
        {id && <Tab label="Contacts" showing={showing} setShowing={setShowing} />}
        {id && <Tab label="Files" showing={showing} setShowing={setShowing} />}
      </TabHeader>
      <TabContent>
        {id && showing === "Notes" && <Notes companyId={id} />}
        {showing === "Contacts" && <ContactTable companyId={id} />}
        {showing === "Address" && <AddressEditor
          address={{
            addressLine1,
            addressLine2,
            city,
            state,
            country,
            zipCode
          }}
          customFields={customFields}
          setAddressLine1={setAddressLine1}
          setAddressLine2={setAddressLine2}
          setCity={setCity}
          setState={setState}
          setCountry={setCountry}
          setZipCode={setZipCode}
          setCustomFields={setCustomFields}
        />}
        {showing === "Tickets" && <TicketTable companyId={id} />}
        {showing === "Files" && <FileTable companyId={id} />}
      </TabContent>
    </>
  );
}