import React from 'react';
import { Link } from 'react-router-dom';
import ContactTable from './ContactTable';

export default function Contacts() {
  return (
    <>

      <div className="row">
        <div className="col-md-6">
          <h3 className="pb-3">Contacts</h3>
        </div>
        <div className="col-md-6">
          <Link to="/contacts/add" className="btn btn-primary float-right">Add Contact</Link>
        </div>
      </div>

       <ContactTable />
    </>
  );
}