import React from 'react';
import { SelectOption } from '../../types';

interface Props {
    setValue(input:any): void;
    label: string;
    value: string | number;
    options: Array<SelectOption>
    allowEmpty?:boolean;
}

export default function SelectControl(props: Props){
    function renderOption(selectOption: SelectOption){
        return <option key={selectOption.value} value={selectOption.value}>{selectOption.label}</option>
    }

    return (
        <div className="form-group">
            <label className="form-label">{props.label}</label>
            <select className="form-control" value={props.value} onChange={e => props.setValue(e.currentTarget.value)} >
                {props.allowEmpty && <option></option>}
                {props.options.map(renderOption)}
            </select>
          </div>
    )
}