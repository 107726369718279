export interface Company {
    customFields: CustomFields;
    id?: string;
    name: string;
    phoneNumber: string;
    emailAddress: string;
    address: Address;
    createdOn?: Date;
    lastUpdated?: Date;
}
export interface Contact {
    id?: string;
    name: string;
    phoneNumber: string;
    emailAddress: string;
    address: Address;
    createdOn?: Date;
    lastUpdated?: Date;
    companyId?: string;
    companyName?: string;
    customFields:CustomFields;
}

export interface PagedContacts {
    contacts: Array<Contact>;
    totalRecordCount: number;
}

export interface CustomFieldDefinition {
    address: Array<FieldDefinition>;
    ticket: Array<FieldDefinition>;
    contact: Array<FieldDefinition>;
    company: Array<FieldDefinition>;
}

export interface CustomFields {
    address: object;
    ticket: object;
    contact: object;
    company: object;
}

export interface FieldDefinition{
        type: "text" | "select" | "checkbox" | "textarea";
        options: Array<SelectOption>;
        label: string;
    }

export interface Ticket {
    customFields: CustomFields;
    id?: string;
    createdOn?: Date;
    lastUpdated?: Date;
    title: string;
    status: TicketStatus;
    description: string;
    companyId?: string;
    contactId?: string;
    companyName?: string;
    contactName?: string;
}

export interface PagedTickets {
    tickets: Array<Ticket>;
    totalRecordCount: number;
}

export interface Dashboard {
    companies: Array<Company>;
    contacts: Array<Contact>;
    tickets: Array<Ticket>
}

export interface PagedCompanies {
    companies: Array<Company>;
    totalRecordCount: number;
}

export interface SelectOption {
    label: string;
    value: any;
}

export interface Note {
    id?: string;
    content: string;
    createdOn?: Date;
    engagementId?: string;
    companyId?: string;
    ticketId?: string;
    contactId?: string;
}

export interface Address {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}

export interface Organization{
    velaroUrl: string;
    velaroSiteId?:number;
    name: string;
    expirationDate: Date;
    customFieldDefinition: CustomFieldDefinition;
}

export interface FileReference {
    name: string;
    uri: string;
    createdOn: Date;
}

export interface TranscriptReference{
    id:string;
    createdOn:Date;
}


export interface TimelineItem {
    eventType: EventType;
    userId: string;
    createdOn: Date;
    companyId:string;
    contactId:string;
    ticketId:string;
    nameReference:string;
    id:string;
}

export interface User {
    displayName: string;
    id: string;

}

export interface SearchResult {
    name:string;
    id:string;
    lastUpdated:Date;
    recordType: "Contact" | "Company" | "Ticket";
}

export enum EventType {
    NoteAdded,
    ContactAdded,
    ContactUpdated,
    TicketAdded,
    TicketUpdated,
    CompanyAdded,
    CompanyUpdated
}

export enum TicketStatus {
    Open,
    Waiting,
    Closed,
    Reopened
}