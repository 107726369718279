import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './Providers/AuthProvider';
import { Routes } from './Routes/Routes';
import "./Styles/site.less"

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter children={Routes} basename={"/"} />
    </AuthProvider>
  );
}