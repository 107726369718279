import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import TextControl from '../Shared/TextControl';
import SelectControl from '../Shared/SelectControl';
import { Contact, CustomFields, SelectOption } from '../../types';
import { useHistory } from 'react-router-dom';
import AddressEditor from '../Shared/AddressEditor';
import Notes from '../Shared/Notes';
import Tab from '../Shared/Tab';
import TabContent from '../Shared/TabContent';
import TabHeader from '../Shared/TabHeader';
import TicketTable from '../Ticket/TicketTable';
import useQuery from '../../Hooks/useQuery';
import FileTable from '../Shared/FileTable';
import CustomFieldsEditor from '../Shared/CustomFieldsEditor';

interface Props {
  match: any
}

export default function EditContact(props: Props) {
  const query = useQuery();
  const api = useApi();
  const history = useHistory();
  const [name, setName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [id, setId] = React.useState(props.match.params.id);
  const [title, setTitle] = React.useState("Add Contact");
  const [companyId, setCompanyId] = React.useState(query.get("companyId") || "");
  const [companyOptions, setCompanyOptions] = React.useState<Array<SelectOption>>([]);
  const [showing, setShowing] = React.useState<"Notes" | "Tickets" | "Address" | "Files">(id ? "Notes" : "Address");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [state, setState] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [customFields, setCustomFields] = React.useState<CustomFields>(
    {address:{},
    company: {},
    contact: {},
    ticket:{}
  });

  const loadContact = React.useCallback(async() => {
    if (!id) {
      return;
    }
    const response = await api.get(`api/contacts/retrieve/${id}`);
    const contact = (await response.json()) as Contact;
    setId(contact.id);
    setName(contact.name);
    setPhoneNumber(contact.phoneNumber);
    setEmailAddress(contact.emailAddress);
    setCompanyId(contact.companyId || '');
    setTitle("Edit Contact");
    setAddressLine1(contact.address.addressLine1);
    setAddressLine2(contact.address.addressLine2);
    setCity(contact.address.city)
    setState(contact.address.state);
    setCountry(contact.address.country);
    setZipCode(contact.address.zipCode);
    setCustomFields(contact.customFields);
  }, [api, id]);

  const loadCompanyOptions = React.useCallback(async() => {
    const response = await api.get('api/companies/options');
    const options = (await response.json());
    setCompanyOptions(options);
  },[api]);

  const load = React.useCallback(async() => {
    await Promise.all([loadContact(), loadCompanyOptions()])
    setLoading(false);
  },[loadContact, loadCompanyOptions]);

  React.useEffect(() => {
    load();
  }, [load]);

  if (loading) {
    return <Loading />
  }

  async function saveContact() {
    const req = {
      address: {
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        zipCode
      },
      emailAddress,
      name,
      phoneNumber,
      id,
      companyId: companyId || undefined,
      customFields
    };

    const response = req.id ? await api.put('api/contacts', req) : await api.post('api/contacts', req);

    if (response.ok) {
      const contact = (await response.json()) as Contact;
      history.push(`/contacts/${contact.id}`);
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h3 className="mb-0 color-base">{title}</h3>
          <hr className="mb-4 color-accentdark" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h4>Details</h4>
          <TextControl label="Name" value={name} setValue={setName} />
          <TextControl label="Phone Number" value={phoneNumber} setValue={setPhoneNumber} />
          <TextControl label="Email Address" value={emailAddress} setValue={setEmailAddress} />
          <SelectControl label="Company" allowEmpty={true} value={companyId} setValue={setCompanyId} options={companyOptions} />
          <CustomFieldsEditor customInformation={customFields} type="contact" setValue={setCustomFields} />
        </div>
        <div className="col-md-6">

        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button className="btn btn-success float-right" onClick={saveContact}>Save</button>
        </div>
      </div>

      <TabHeader>
        {id && <Tab label="Notes" showing={showing} setShowing={setShowing} />}
        <Tab label="Address" showing={showing} setShowing={setShowing} />
        {id && <Tab label="Tickets" showing={showing} setShowing={setShowing} />}
        {id && <Tab label="Files" showing={showing} setShowing={setShowing} />}
      </TabHeader>
      <TabContent>
        {showing === "Notes" && <Notes contactId={id} />}
        {showing === "Address" && <AddressEditor
          address={{
            addressLine1,
            addressLine2,
            city,
            state,
            country,
            zipCode
          }}
          customFields={customFields}
          setAddressLine1={setAddressLine1}
          setAddressLine2={setAddressLine2}
          setCity={setCity}
          setState={setState}
          setCountry={setCountry}
          setZipCode={setZipCode}
          setCustomFields={setCustomFields}
        />}
        {showing === "Tickets" && <TicketTable contactId={id} />}
        {showing === "Files" && <FileTable contactId={id} />}
      </TabContent>
    </>
  );
}