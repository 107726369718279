import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import { Ticket, TicketStatus } from '../../types';
import Notes from '../Shared/Notes';
import Display from '../Shared/Display';
import DisplayLink from '../Shared/DisplayLink';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TabHeader from '../Shared/TabHeader';
import Tab from '../Shared/Tab';
import TabContent from '../Shared/TabContent';
import TextAreaDisplay from '../Shared/TextAreaDisplay';
import SelectDisplay from '../Shared/SelectDisplay';
import TicketStatusOptions from '../../Assets/TicketStatus';
import Timeline from '../Shared/Timeline';
import { OrganizationContext } from '../../Contexts/OrganizationContext';
import TranscriptTable from '../Shared/TranscriptTable';
import FileTable from '../Shared/FileTable';
import CustomFieldsDisplay from '../Shared/CustomFieldsDisplay';

interface Props {
  match: any;
}

export default function ViewTicket(props: Props) {
  const api = useApi();
  const [ticket, setTicket] = React.useState<Ticket>({
    description: '',
    status: TicketStatus.Open,
    title: '',
    customFields: {
      address: {},
      company: {},
      contact: {}, 
      ticket: {}
    }
  });
  const [showing, setShowing] = React.useState<"Notes" | "Transcripts" | "Files">("Notes");
  const [loading, setLoading] = React.useState<boolean>(true);

  const loadTicket = React.useCallback(async () => {
    const response = await api.get(`api/tickets/retrieve/${props.match.params.id}`);
    setTicket(await response.json());
    setLoading(false);
  }, [api, props.match.params.id]);

  React.useEffect(() => {
    loadTicket();
  }, [loadTicket]);

  if (loading) {
    return <Loading />
  }

  const editLink = `/tickets/edit/${ticket.id}`;

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <h3 className="mb-0">{ticket.title}</h3>
        </div>
        <div className="col-md-4">
          <Link to={editLink} className="btn btn-primary float-right">Edit Ticket</Link>
        </div>
        <div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <hr className="mb-4 color-accentdark" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h4>Details</h4>
          <TextAreaDisplay label="Description" value={ticket.description} />
          <SelectDisplay label="Status" value={ticket.status} options={TicketStatusOptions} />
          <DisplayLink label="Company" value={ticket.companyName} href={"/companies/" + ticket.companyId} />
          <DisplayLink label="Contact" value={ticket.contactName} href={"/contacts/" + ticket.contactId} />
          <Display label="Created" value={moment.utc(ticket.createdOn).local().calendar()} />
          <Display label="Last Updated" value={moment.utc(ticket.lastUpdated).local().calendar()} />
          <CustomFieldsDisplay customInformation={ticket.customFields} type="ticket" />
        </div>
        <div className="col-md-6">
          <Timeline ticketId={ticket.id} />
        </div>
      </div>

      <TabHeader>
        <Tab label="Notes" showing={showing} setShowing={setShowing} />
        <OrganizationContext.Consumer>
            {org => 
              org?.velaroSiteId && <Tab label="Transcripts" showing={showing} setShowing={setShowing} ></Tab>
            }
          </OrganizationContext.Consumer>
          <Tab label="Files" showing={showing} setShowing={setShowing} />
      </TabHeader>
      <TabContent>
        {showing === "Notes" && <Notes ticketId={ticket.id} />}
        {showing === "Transcripts" && <TranscriptTable ticketId={ticket.id} />}
        {showing === "Files" && <FileTable ticketId={ticket.id} />}
      </TabContent>
    </>
  );
}