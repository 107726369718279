import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import { Contact, PagedContacts } from '../../types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pager from '../Shared/Pager';
import LetterFilter from '../Shared/LetterFilter';

interface Props {
  companyId?: string;
}

export default function ContactTable(props: Props) {
  const [loading, setLoading] = React.useState(true);
  const [contacts, setContacts] = React.useState<Array<Contact>>([]);
  const [page, setPage] = React.useState(0);
  const [totatRecordCount, setTotalRecordCount] = React.useState(0);
  const [filter, setFilter] = React.useState("");
  const api = useApi();

  const loadContacts = React.useCallback(async () => {
    setLoading(true);
    const response = await api.get('api/contacts/page', { page, filter, companyId: props.companyId });
    const paged = (await response.json()) as PagedContacts;
    setContacts(paged.contacts);
    setTotalRecordCount(paged.totalRecordCount);
    setLoading(false);
  }, [page, filter, api, props.companyId]);

  React.useEffect(() => {
    loadContacts();
  }, [loadContacts]);


  const renderLoading = React.useCallback(() => {
    if (!loading) {
      return null;
    }
    return <tr style={{ background: "transparent" }}>
      <td colSpan={100}>
        <Loading />
      </td>
    </tr>
  }, [loading]);

  const renderContact = React.useCallback((contact: Contact) => {
    const href = `/contacts/${contact.id}`;
    return (
      <tr key={contact.id}>
        <td><Link to={href} >{contact.name}</Link></td>
        <td>{moment.utc(contact.createdOn).local().calendar()}</td>
        <td>{moment.utc(contact.lastUpdated).local().calendar()}</td>
        <td>{contact.phoneNumber}</td>
        <td>{contact.emailAddress}</td>
      </tr>
    )
  }, []);

  const renderEmpty = React.useCallback(() => {
    if (contacts.length || loading) {
      return null;
    }

    return (
      <tr>
        <td className="text-center" colSpan={100}>
          <h6>No results</h6>
        </td>
      </tr>
    )
  }, [contacts, loading]);

  const updateFilter = React.useCallback((value: string) => {
    setPage(0);
    setFilter(value);
  }, [])


  return (
    <>
      {props.companyId &&
        <div className="row">
          <div className="col-md-12" style={{marginBottom:"10px"}}>
            <div className="float-right">
              <Link to={`/contacts/add?companyId=${props.companyId}`} className="btn btn-primary float-right">Add Contact</Link>
              <div className="clearfix" />
            </div>
          </div>
        </div>}
      <div className="row">
        <div className="col-md-8">
          <LetterFilter filter={filter} setFilter={updateFilter} />
        </div>
        <div className="col-md-4">
          <div className="float-right">
            <Pager page={page} pageSize={20} totalRecordCount={totatRecordCount} setPage={setPage} />
          </div>
        </div>
      </div>

      <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Created On</th>
            <th>Last Updated</th>
            <th>Phone Number</th>
            <th>Email Address</th>
          </tr>
        </thead>
        <tbody>
          {renderLoading()}
          {contacts.map(renderContact)}
          {renderEmpty()}
        </tbody>
      </table>
    </>
  );
}