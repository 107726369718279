import React from 'react';
import logoUrl from "../Assets/logo.png"


export default function Loading() {
  return (
    <div className="loading-container">
      <img className="loading-spinner" alt="loading" src={logoUrl} />
    </div>
  );
}