import React from 'react';
import { Link } from 'react-router-dom';
import { EventType, TimelineItem, User } from '../../types';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import moment from "moment";
import * as $ from "jquery";
import { UsersContext } from '../../Contexts/UsersContext';
import { uniqueId } from 'underscore';

interface Props {
    companyId?: string;
    ticketId?: string;
    contactId?: string;
}

export default React.memo((props: Props) => {
    const api = useApi();
    const [loading, setLoading] = React.useState(true);
    const [items, setItems] = React.useState<Array<TimelineItem>>([]);

    const load = React.useCallback(async () => {
        setLoading(true);
        const response = await api.get(`api/timeline?${$.param(props)}`);
        setItems(await response.json());
        setLoading(false);
    }, [api, props]);

    React.useEffect(() => {
        load();
    }, [load])

    const getStripColor = React.useCallback((eventType: EventType) => {
        switch (eventType) {
            case EventType.CompanyAdded:
            case EventType.CompanyUpdated:
                return "blue"
            case EventType.ContactAdded:
            case EventType.ContactUpdated:
                return "green"
            case EventType.TicketAdded:
            case EventType.TicketUpdated:
                return "darkblue";
            default:
                return "blue";
        }
    }, []);


    const renderItem = React.useCallback((item: TimelineItem, users: Array<User>) => {
        let link = null;
        let filteredUsers = users.filter(x => x.id === item.userId);
        let displayName = null;
        if (filteredUsers.length) {
            displayName = filteredUsers[0].displayName;
        }
        switch (item.eventType) {
            case EventType.CompanyAdded:
                link = <Link to={`/companies/${item.companyId}`}>{`${item.nameReference || "Company"} was created`}</Link>
                break;
            case EventType.CompanyUpdated:
                link = <Link to={`/companies/${item.companyId}`}>{`${item.nameReference || "Company"} was updated`}</Link>
                break;
            case EventType.ContactAdded:
                link = <Link to={`/contacts/${item.contactId}`}>{`${item.nameReference || "Contact"} was created`}</Link>
                break;
            case EventType.ContactUpdated:
                link = <Link to={`/contacts/${item.contactId}`}>{`${item.nameReference || "Contact"} was updated`}</Link>
                break;
            case EventType.TicketAdded:
                link = <Link to={`/tickets/${item.ticketId}`}>{`${item.nameReference || "Ticket"} was created`}</Link>
                break;
            case EventType.TicketUpdated:
                link = <Link to={`/tickets/${item.ticketId}`}>{`${item.nameReference || "Ticket"} was updated`}</Link>
                break;
            case EventType.NoteAdded:
                let href = `/tickets/${item.ticketId}`;
                let nameReference = item.nameReference || "Ticket";

                if (item.contactId) {
                    href = `/contacts/${item.contactId}`;
                    nameReference = item.nameReference || "Contact"
                }

                if (item.companyId) {
                    href = `/companies/${item.companyId}`;
                    nameReference = item.nameReference || "Company"
                }
                link = <Link to={href}>Note was added to {nameReference}</Link>
                break;
        }

        return (
            <div key={uniqueId()}>
                <div className="timeline-item">
                    {link}
                    <div style={{ paddingBottom: "3px" }}>
                        <small className="float-left">by {displayName?.trim() || "Guest"}</small>
                        <small className="float-right">{moment.utc(item.createdOn).local().calendar()}</small>
                        <div className="clearfix" />
                    </div>
                    <div className={`color-strip ${getStripColor(item.eventType)}`} />
                </div>
                <div className="timeline-divider" >
                    <div className="line"></div>
                </div>
            </div>
        )
    }, [getStripColor]);

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <h4>Activity Feed</h4>
            {items.length == 0 && "No Recent Activity"}
            <UsersContext.Consumer>
                {users => {
                    return items.map(x => renderItem(x, users))
                }
                }
            </UsersContext.Consumer>
        </>
    )
})


