import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { LoginCallback } from "../Views/Auth/LoginCallback";
import { Logout } from "../Views/Auth/Logout";
import { LogoutCallback } from "../Views/Auth/LogoutCallback";
import { PrivateRoute } from "./PrivateRoute";
import Companies from "../Views/Company/Companies";
import EditCompany from "../Views/Company/EditCompany";
import ViewCompany from "../Views/Company/ViewCompany";
import Contacts from '../Views/Contact/Contacts';
import Tickets from '../Views/Ticket/Tickets';
import EditTicket from '../Views/Ticket/EditTicket';
import EditContact from '../Views/Contact/EditContact';
import ViewContact from '../Views/Contact/ViewContact';
import Dashboard from '../Views/Dashboard';
import ViewTicket from '../Views/Ticket/ViewTicket';
import LoginCallbackError from "../Views/Auth/LoginErrorCallback";

export const Routes = (

    <Switch>
        <Route exact={true} path="/login/callback/error" component={LoginCallbackError} />
        <Route exact={true} path="/login/callback" component={LoginCallback} />
        <Route exact={true} path="/logout" component={Logout} />
        <Route exact={true} path="/logout/callback" component={LogoutCallback} />


        <PrivateRoute path="/companies/add" component={EditCompany} />
        <PrivateRoute path="/companies/edit/:id" component={EditCompany} />
        <PrivateRoute path="/companies/:id" component={ViewCompany} />
        <PrivateRoute path="/companies" component={Companies} />

        <PrivateRoute path="/contacts/add" component={EditContact} />
        <PrivateRoute path="/contacts/edit/:id" component={EditContact} />
        <PrivateRoute path="/contacts/:id" component={ViewContact} />
        <PrivateRoute path="/contacts" component={Contacts} />

        <PrivateRoute path="/tickets/add" component={EditTicket} />
        <PrivateRoute path="/tickets/add/" component={EditTicket} />
        <PrivateRoute path="/tickets/edit/:id" component={EditTicket} />
        <PrivateRoute path="/tickets/:id" component={ViewTicket} />
        <PrivateRoute path="/tickets" component={Tickets} />

        <PrivateRoute path="/" component={Dashboard} />

    </Switch>
);