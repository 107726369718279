import React from "react";
import { UsersContext } from "../Contexts/UsersContext";
import useApi from "../Hooks/useApi";
import { User } from "../types";
import Loading from "../Views/Loading";

export function UsersProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [users, setUsers] = React.useState<Array<User>>([]);
  const [loading, setLoading] = React.useState(true);

  const load = React.useCallback(async() =>{
    const response = await api.get('api/user');
    setUsers(await response.json());
    setLoading(false);
  },[api]);

  React.useEffect(() => {
    load();
  },[load])

  if(loading){
    return <Loading />
  }


  return (
    <UsersContext.Provider value={users}>
      {props.children}
    </UsersContext.Provider>
  );
}