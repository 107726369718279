import React from 'react';
import { Link } from 'react-router-dom';
import TicketTable from './TicketTable';

export default function Tickets() {
  return (
    <>

      <div className="row">
        <div className="col-md-6">
          <h3 className="pb-3">Tickets</h3>
        </div>
        <div className="col-md-6">
          <Link to="/tickets/add" className="btn btn-primary float-right">Add Ticket</Link>
        </div>
      </div>

       <TicketTable />
    </>
  );
}