import React from 'react';

interface Props {
    label: string;
    value: boolean;
}

export default function CheckboxDisplay(props: Props){
    return (
        <div className="form-group">
        <div className="form-check">
            <input type="checkbox" disabled={true} className="form-check-input checkbox-1x" checked={props.value} />
            <label className="form-check-label">{props.label}</label>
          </div>
        </div>
    )
}