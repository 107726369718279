import React from 'react';
import Loading from '../Loading';
import useApi from '../../Hooks/useApi';
import { Company } from '../../types';
import TextDisplay from '../Shared/TextDisplay';
import Notes from '../Shared/Notes';
import Display from '../Shared/Display';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ContactTable from '../Contact/ContactTable';
import TabHeader from '../Shared/TabHeader';
import TabContent from '../Shared/TabContent';
import Tab from '../Shared/Tab';
import AddressDisplay from '../Shared/AddressDisplay';
import TicketTable from '../Ticket/TicketTable';
import Timeline from '../Shared/Timeline';
import { OrganizationContext } from '../../Contexts/OrganizationContext';
import TranscriptTable from '../Shared/TranscriptTable';
import FileTable from '../Shared/FileTable';
import CustomFieldsDisplay from '../Shared/CustomFieldsDisplay';

interface Props {
  match: any;
}

export default function ViewCompany(props: Props) {
  const api = useApi();
  const [company, setCompany] = React.useState<Company>({
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      country: "",
      state: "",
      zipCode: ""
    },
    emailAddress: "",
    name: "",
    phoneNumber: "",
    customFields: {
      address: {},
      company: {},
      contact: {},
      ticket: {}
    }
  });
  const [showing, setShowing] = React.useState<"Notes" | "Tickets" | "Contacts" | "Address" | "Transcripts" | "Files">("Notes");
  const [loading, setLoading] = React.useState<boolean>(true);

  const loadCompany = React.useCallback(async () => {
    const response = await api.get(`api/companies/retrieve/${props.match.params.id}`);
    setCompany(await response.json());
    setLoading(false);
  }, [api, props.match.params.id]);

  React.useEffect(() => {
    loadCompany();
  }, [loadCompany]);

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <h3 className="mb-0">{company.name}</h3>
        </div>
        <div className="col-md-4">
          <Link to={`/companies/edit/${company.id}`} className="btn btn-primary float-right">Edit Company</Link>
        </div>
        <div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <hr className="mb-4 color-accentdark" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h4>Details</h4>
          <Display label="Created" value={moment.utc(company.createdOn).local().calendar()} />
          <Display label="Last Updated" value={moment.utc(company.lastUpdated).local().calendar()} />
          <TextDisplay label="Phone Number" value={company.phoneNumber} />
          <TextDisplay label="Email Address" value={company.emailAddress} />
          <CustomFieldsDisplay customInformation={company.customFields} type="company" />
        </div>
        <div className="col-md-6">
          <Timeline companyId={company.id} />
        </div>
      </div>

      <TabHeader>
        <Tab label="Notes" showing={showing} setShowing={setShowing} />
        <Tab label="Address" showing={showing} setShowing={setShowing} />
        <Tab label="Tickets" showing={showing} setShowing={setShowing} />
        <Tab label="Contacts" showing={showing} setShowing={setShowing} />
          <OrganizationContext.Consumer>
            {org => 
              org?.velaroSiteId && <Tab label="Transcripts" showing={showing} setShowing={setShowing} ></Tab>
            }
          </OrganizationContext.Consumer>
          <Tab label="Files" showing={showing} setShowing={setShowing} />
      </TabHeader>
      <TabContent>
        {showing === "Notes" && <Notes companyId={company.id} />}
        {showing === "Contacts" && <ContactTable companyId={company.id} />}
        {showing === "Address" && <AddressDisplay customInformation={company.customFields} address={company.address} />}
        {showing === "Tickets" && <TicketTable companyId={company.id} />}
        {showing === "Transcripts" && <TranscriptTable companyId={company.id} />}
        {showing === "Files" && <FileTable companyId={company.id} />}
      </TabContent>
    </>
  );
}