import React from 'react';

interface Props {
    label: string;
    value?: string;
}

export default function TextAreaDisplay(props: Props){
    return (
        <div className="form-group">
            <label className="form-label">{props.label}</label>
            <textarea rows={4} disabled className="form-control" value={props.value} />
          </div>
    )
}