import React from 'react';
import { Note } from '../../types';
import useApi from '../../Hooks/useApi';
import moment from 'moment';
import Loading from '../Loading';

interface Props {
    companyId?: string;
    contactId?: string;
    ticketId?: string;
}

export default React.memo((props: Props) => {
    const api = useApi();
    const [loading, setLoading] = React.useState(true);
    const [reachedEnd, setReachedEnd] = React.useState(false);
    const [notes, setNotes] = React.useState<Array<Note>>([]);
    const [content, setContent] = React.useState("");

    
    const getNotes = React.useCallback(async():Promise<Response> =>  {
        let route = 'company';
        let params = {
            id: props.companyId || props.contactId || props.ticketId,
            skip: notes.length
        }

        if(props.contactId){
            route = 'contact'
        }
        
        if(props.ticketId){
            route = 'ticket'
        }

        return await api.get(`api/notes/${route}`, params);
    },[api, notes.length, props.companyId, props.contactId, props.ticketId]);

    const loadNotes = React.useCallback(async() => {
        setLoading(true);
        const response = await getNotes();
        var noteResponse = (await response.json()) as Array<Note>;
        setReachedEnd(noteResponse.length === 0);
        setNotes(n => [...n, ...noteResponse]);
        setLoading(false);
    }, [getNotes]);

    var handleScroll = React.useCallback((e:any) => {
       if(!reachedEnd && e.currentTarget.scrollTop - e.currentTarget.offsetHeight === -e.currentTarget.scrollHeight){
        loadNotes();
       }
    }, [reachedEnd, loadNotes]);


    async function addNote() {
        if(!content.trim()){
            return;
        }

        const response = await api.post('api/notes/postnote',{
            content,
            companyId: props.companyId,
            contactId: props.contactId,
            ticketId: props.ticketId
        });
        const note = await response.json();
        setNotes([note, ...notes]);
        setContent('');
    }

    const renderNote = React.useCallback((note: Note) => {
        return (
            <div className="clearfix" key={note.id}>
                <div className="note">
                    <div>{note.content}</div>
                </div>
                <small className="float-right text-muted mr-4">{moment.utc(note.createdOn).local().calendar()}</small>
            </div>
        )
    }, [])

    function onKeyPress(e:any){
        if(e.key === "Enter" && !e.shiftKey){
            addNote();
        }
    }


    const renderLoading = React.useCallback(() => {
        if(!loading){
            return null;
        }
        return <Loading />
    }, [loading]);

    const renderEmpty = React.useCallback(() => {
        if(loading || notes.length){
            return null;
        }
        return (
            <div className="notes-empty">
                <h5>No notes exist</h5>
            </div>
        )
    }, [loading, notes]);

    React.useEffect(() => {
        loadNotes();
    }, [loadNotes]);


    return (
        <>
            <div className="note-container" onScroll={handleScroll}>
                {notes.map(renderNote)}
                {renderLoading()}
                {renderEmpty()}
            </div>

            <textarea className="form-control mt-2" value={content} onKeyPress={e => onKeyPress(e)} onChange={e => setContent(e.currentTarget.value)}>

            </textarea>
            <button className="btn btn-primary float-right mt-2" onClick={addNote}>Add Note</button>
        </>
    )
})