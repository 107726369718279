import React from 'react';
import { Company, Contact } from '../../types';
import Card from '../Shared/Card';
import  { Link } from 'react-router-dom';

interface Props {
    contacts: Array<Contact>;
}

export default function RecentlyUpdatedContacts(props: Props) {


    const renderContact = React.useCallback((contact:Company) => {
        const href = `/contacts/${contact.id }`;
        return (
            <div key={contact.id}>
                <Link to={href}>
                    {contact.name}
                </Link>
            </div>
        )
    }, []);

    return (
        <Card title="Recent Contacts" color="bg-accentmed">
            {props.contacts.map(renderContact)}
        </Card>
    )
}