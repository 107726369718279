import React from 'react';

interface Props {
    
}

export default function TabContent(props: React.PropsWithChildren<Props>) {
    return (
        <div className="card mt-3">
            <div className="card-body tab-content">
                    {props.children}
            </div>
        </div>
    )
}