import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    label: string;
    value?: string;
    href?: string;
}

export default function DisplayLink(props: Props) {
    return (
        <div className="form-group">
            <label className="form-label">{props.label}</label>
            <div>
                <label className="form-label">{props.value && props.href && <Link to={props.href}>{props.value}</Link>}</label>
            </div>
        </div>
    )
}