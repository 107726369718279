import React from "react";
import { OrganizationContext } from "../Contexts/OrganizationContext";
import useApi from "../Hooks/useApi";
import { Organization } from "../types";
import Loading from "../Views/Loading";

export function OrganizationProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [organization, setOrganization] = React.useState<Organization>({
    customFieldDefinition: {
      address: [],
      company: [],
      contact: [],
      ticket: []
    },
    expirationDate: new Date(),
    name: "",
    velaroUrl: "",
    velaroSiteId: 0
  });
  const [loading, setLoading] = React.useState(true);

  const load = React.useCallback(async () => {
    const response = await api.get('api/organization');
    setOrganization(await response.json());
    setLoading(false);
  }, [api]);

  React.useEffect(() => {
    load();
  }, [load])

  if (loading) {
    return <Loading />
  }


  return (
    <OrganizationContext.Provider value={organization}>
      {props.children}
    </OrganizationContext.Provider>
  );
}