import React from 'react';

interface Props {
    label: string;
    value?: string;
}

export default function Display(props: Props) {
    return (
        <div className="form-group">
            <label className="form-label">{props.label}</label>
            <div>
                <label className="form-label">{props.value}</label>
            </div>
        </div>
    )
}