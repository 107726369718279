import { TicketStatus } from "../types";

export default [
    {
        label: "Open",
        value: TicketStatus.Open
    },
    {
        label: "Waiting",
        value: TicketStatus.Waiting
    },
    {
        label: "Closed",
        value: TicketStatus.Closed
    },
    {
        label: "Re-Opened",
        value: TicketStatus.Reopened
    },
    ]