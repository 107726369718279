import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
interface Props {
    children: React.ReactNode;
  }

export default function Layout(props:Props){
    return(
        <div className="App">
      <div className="container-fluid">
        <div className="row">
          <Navbar />
          <Sidebar />
          <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4 p-3" style={{ marginTop: "70px" }}>
            {props.children}
          </main>
        </div>
      </div>
    </div>
    )
}