import React from 'react';
import TextDisplay from './TextDisplay';
import SelectDisplay from './SelectDisplay';
import { Address, CustomFields } from '../../types';
import StateCodes from '../../Assets/StateCodes';
import CustomFieldsDisplay from './CustomFieldsDisplay';

interface Props {
    address: Address;
    customInformation:CustomFields;
}

export default React.memo((props: Props) => {
        return (
            <>
                <TextDisplay label="Address Line 1" value={props.address.addressLine1} />
                <TextDisplay label="Address Line 2" value={props.address.addressLine2} />
                <TextDisplay label="City" value={props.address.city} />
                <SelectDisplay label="State" value={props.address.state} options={StateCodes} />
                <TextDisplay label="Zip Code" value={props.address.zipCode} />
                <TextDisplay label="Country" value={props.address.country} />
                <CustomFieldsDisplay customInformation={props.customInformation} type="address" />
            </>
        )
})