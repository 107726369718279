import React from 'react';
import RecentlyUpdateCompanies from './RecentlyUpdatedCompanies';
import RecentlyUpdatedContacts from './RecentlyUpdatedContacts';
import useApi from '../../Hooks/useApi';
import { Dashboard } from '../../types';
import Loading from '../Loading';
import Timeline from '../Shared/Timeline';
import RecentlyUpdatedTickets from './RecentlyUpdatedTickets';

export default function Home() {
    const api = useApi();
    const [loading, setLoading] = React.useState(true);
    const [dashboard, setDashboard] = React.useState<Dashboard>({
        companies: [],
        contacts: [],
        tickets:[]
    });

    const loadDashboard = React.useCallback(async () => {
        setLoading(true);
        const response = await api.get('api/dashboard');
        const dashboard = (await response.json()) as Dashboard;
        setDashboard(dashboard);
        setLoading(false);
    }, [api]);

    React.useEffect(() => {
        loadDashboard();
    }, [loadDashboard])

    const renderContent = React.useCallback(() => {
        if (loading) {
            return (
                <div className="col-md-12">
                    <Loading />;
                </div>
            )
        }

        return (
            <>
                <div className="col-lg-8 col-md-12">
                    <div className="row mb-3">
                    <div className="col-md-6">
                        <RecentlyUpdateCompanies companies={dashboard.companies} />
                    </div>
                    <div className="col-md-6">
                        <RecentlyUpdatedContacts contacts={dashboard.contacts} />
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-6">
                        <RecentlyUpdatedTickets tickets={dashboard.tickets} />
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-12">
                    <Timeline />
                </div>
            </>
        )
    }, [loading, dashboard]);


    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h3 className="pb-3">Dashboard</h3>
                </div>
            </div>
            <div className="row">
                {renderContent()}
            </div>
        </>
    )
}