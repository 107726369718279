import React from "react";
import useAuth from "../../Hooks/useAuth";
import Loading from "..//Loading";

export function LogoutCallback() {
  const auth = useAuth();

  React.useLayoutEffect(() => {
    auth.logoutCallback();
  }, [auth]);

  return <Loading />;
}