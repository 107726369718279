import React from 'react';

interface Props {
    title: string;
    color: "bg-base" | "bg-accentdark" | "bg-accentmed" | "bg-accentlight"
}

export default function Card(props: React.PropsWithChildren<Props>) {
    const headerClass = `card-header ${props.color}`;
    return (
        <div className="card">
            <div className={headerClass}>
                {props.title}
            </div>
            <div className="card-body">
                {(props.children as Array<Node>).length > 0 ? props.children : "No results"}
            </div>
        </div>
    )
}