import React, { useRef } from "react";
import useApi from "../../Hooks/useApi";
import { FileReference } from "../../types";
import Loading from "../Loading";
import * as $ from "jquery";
import moment from "moment";
import { TrashIcon } from "@primer/octicons-react";

interface Props {
  companyId?: string;
  contactId?: string;
  ticketId?: string;
}

export default (props: Props) => {
  const id = props.companyId || props.contactId || props.ticketId || "unknown";
  const tenMB = 10485760;
  const fileInput = useRef(null);
  const api = useApi();
  const [loading, setLoading] = React.useState(true);
  const [files, setFiles] = React.useState<Array<FileReference>>([]);

  const load = React.useCallback(async () => {
    const response = await api.get(`api/files?${$.param({ id })}`);
    setFiles(await response.json());
    setLoading(false);
  }, [api])

  const deleteFile = React.useCallback(async (file:FileReference) => {
    const response = await api.delete(`api/files?${$.param({ id })}`, file);
    setFiles(f => f.filter(x => x.name !== file.name));
  },[setFiles]);

  const renderFile = React.useCallback((file: FileReference) => {
    return (
      <tr key={file.name}>
        <td>
          <a download href={file.uri}>
            {file.name}
          </a>
        </td>
        <td>{moment.utc(file.createdOn).local().calendar()}</td>
        <td><a href="#" onClick={e => {e.preventDefault(); deleteFile(file)}}><TrashIcon /></a></td>
      </tr>
    )
  }, []);


  const onClickUploadFile = React.useCallback(async (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files === null) {
      return;
    }

    const file = e.currentTarget.files[0];

    if (file.size > tenMB) {
      alert("File too large");
      e.currentTarget.value = "";
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", id)

    const promise = api.uploadFile('api/files/uploadfile', formData);
    e.currentTarget.value = "";

    promise.then(async result => {
      const file = await result.json();
      setFiles(f => [...f, file])
    })
  }, [setFiles]);

  React.useEffect(() => {
    load();
  }, [load]);


  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="text-center">
        {files.length === 0 && <div className="p-4"><h6>Nothing here</h6></div>}
      </div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        onChange={onClickUploadFile}
      />
      {files.length > 0 && <table className="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {files.map(renderFile)}
        </tbody>
      </table>}
      <div className="text-center">
        <button onClick={() => (fileInput.current as any).click()} className="btn btn-primary">Upload File</button>
      </div>
    </>
  )
}
