import React from 'react';

interface Props {
    filter: string;
    setFilter(filter:string):void;
}

export default function LetterFilter(props:Props){
    const alphabet = ['All', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    
    function onClick(e:any, filter:string) {
        e.preventDefault();
        if(filter === 'All'){
            filter = '';
        }
        props.setFilter(filter);
    }

    return <div className="letter-filter">
        {alphabet.map(letter => {
            let className = letter === props.filter ? 'active': '';
            if(letter === 'All' && !props.filter){
                className = 'active';
            }
            return <a href="javascript(void)" key={letter} className={className}  onClick={e => onClick(e,letter)}>{letter}</a>
        })}
    </div>
}