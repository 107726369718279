import useAuth from "../../Hooks/useAuth";
import React from "react";

export default function LoginCallbackError() {
  const auth = useAuth();

  return (
    <div style={{ textAlign: "center", padding: 20 }}>
      <p>An unexpected error has occurred while attempting to log in.</p>
      <div>
        <button className="btn btn-default"
          onClick={() => {
            auth.retryLogin();
          }}
        >
          Retry
        </button>
      </div>
    </div>
  );
}
