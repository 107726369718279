import React from 'react';
import { SelectOption } from '../../types';

interface Props {
    label: string;
    value: any;
    options: Array<SelectOption>;
}

export default function SelectDisplay(props: Props){
    function renderOption(selectOption: SelectOption){
        return <option key={selectOption.value} value={selectOption.value}>{selectOption.label}</option>
    }

    return (
        <div className="form-group">
            <label className="form-label">{props.label}</label>
            <select className="form-control" disabled value={props.value}  >
                <option></option>
                {props.options.map(renderOption)}
                </select>
          </div>
    )
}